<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting">
    <template v-slot:title>{{ $t('labels.firm') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <FirmField v-model="form.firm_code" item-value="firm_code" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/entities/store'

import { FirmField } from '../../firms/components'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'

export default {
  name: 'FirmModal',
  extends: BaseForm,
  mixins: [DealInfoModalMixin],
  props: {
    firmCode: String
  },
  components: {
    VModalWidget,
    FormErrors,
    FirmField
  },
  validations: {
    form: {
      firm_code: { required }
    }
  },
  data: () => ({
    form: {
      firm_code: null
    }
  }),
  computed: {
    agentErrors() {
      const errors = []
      if (!this.$v.form.firm_code.$dirty) return errors
      !this.$v.form.firm_code.required && errors.push(this.$t('required'))
      this.getServerErrors('firm_code', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        proxy: this.entityURL,
        url: this.baseURL,
        data: this.form
      }
      this.patchEntity(payload)
        .then((resp) => {
          return this.getBoardItem({ url: this.entityURL })
        })
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.firmCode !== 'new') {
      this.form.firm_code = this.firmCode
    }
  }
}
</script>

<i18n>
{
  "en": { "title": "Set Insurer"},
  "fr": { "title": "Définir l'assureur"}
}
</i18n>
